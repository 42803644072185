import { IInviteLinkProviderProps } from './interfaces';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeId } from '../storage-contexts/Challenge/helpers/getChallengeId';
import { getInvitationLink } from '../../selectors/getInvitationLink';
import { isMockedChallenge } from '../main/getMockedChallenges';
import { locationProviderPropsMap } from '../Location/locationProviderPropsMap';

let CHALLENGES_INVITATION_LINK: string = null;

export async function inviteLinkPropsMap(
  flowAPI: ControllerFlowAPI,
): Promise<IInviteLinkProviderProps> {
  const { isEditor } = flowAPI.environment;
  const challengeId = await getChallengeId(flowAPI);
  const { goToExternalUrl } = await locationProviderPropsMap(flowAPI);

  if (isMockedChallenge(challengeId, flowAPI)) {
    return {
      goToInviteLink: () => {},
    };
  }

  async function getInviteLink(msid: string) {
    const inviteLink =
      isEditor || !msid
        ? ''
        : CHALLENGES_INVITATION_LINK ||
          (await getInvitationLink(flowAPI, challengeId, msid));

    if (!CHALLENGES_INVITATION_LINK) {
      CHALLENGES_INVITATION_LINK = inviteLink;
    }
    return inviteLink;
  }

  try {
    const msid = flowAPI.controllerConfig.platformAPIs?.bi?.metaSiteId;

    return {
      goToInviteLink: async () => {
        const inviteLink = await getInviteLink(msid);
        goToExternalUrl(inviteLink);
      },
      setInviteLink: async () => {
        const inviteLink = await getInviteLink(msid);
        flowAPI.controllerConfig.setProps({ inviteLink });
      },
    };
  } catch (error) {
    console.error(error);
  }

  return {
    goToInviteLink: () => {},
  };
}
